import { useState } from "react";
import cookie from "js-cookie";
import loadConfig from "../utils/loadConfig";
import { getReCaptchaToken } from "../utils/loadRecaptcha";

const API_REGISTER = "/api/register";
const COOKIE_KEY = "ALSGDLKSA";
const DAYS_7 = 7;

const apiRegister = (email, listId, token) => {
  const data = {
    email,
  };

  if (listId) {
    data.listId = listId;
  }

  if (token) {
    data["g-recaptcha-response"] = token;
  }

  return fetch(API_REGISTER, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

const useEmailSend = () => {
  const [status, setStatus] = useState(
    cookie.get(COOKIE_KEY) === "1" ? true : null
  );

  async function sendEmail(email, listId = null) {
    setStatus(true);

    const config = await loadConfig();
    const token = await getReCaptchaToken(config.recaptcha);

    return apiRegister(email, listId, token)
      .then((res) => {
        const status = res?.data?.status !== false;

        setStatus(status);

        if (status) {
          cookie.set(COOKIE_KEY, "1", { expires: DAYS_7, path: "" });
        }
      })
      .catch(() => {
        setStatus(false);
      });
  }

  return [status, sendEmail];
};

export default useEmailSend;
