import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import Header from "../components/Header/Header";
import VideoSlider from "../components/VideoSlider/VideoSlider";
import Plan from "../components/Plan/Plan";
import SpeakersList from "../components/SpeakersList/SpeakersList";
import PromoVideo from "../components/PromoVideo/PromoVideo";
import Partners from "../components/Partners/Partners";
import SignInForConference from "../components/SignInForConference/SignInForConference";
import NotPromoting from "../components/NotPromoting/NotPromoting";
import JoinCommunity from "../components/JoinCommunity/JoinCommunity";
import RigaBitcoinWeek from "../components/RigaBitcoinWeek/RigaBitcoinWeek";
import RigaHotels from "../components/RigaHotels/RigaHotels";

function Main() {
  const { t } = useTranslation();

  return (
    <div className="page">
      <Helmet>
        <title>{t("baltic.honey.europeanBitcoinEventTitle")}</title>
        <meta
          name="description"
          content="Join the 6th and the Biggest edition of Bitcoin only Conference 2024 held in Europe, Latvia."
        />
      </Helmet>
      <Header
        title="Baltic HoneyBadger 2024"
        subtitle="The most OG Bitcoin conference in the world"
        alt="Baltic HoneyBadger 2024"
      ></Header>
      <main>
        <section className="page-container">
          <Plan />
          <SpeakersList />
          <NotPromoting text='Not promoting crypto, various so called altcoins, tokens and other blockchain-based "snake oil"' />
        </section>
        <section>
          <PromoVideo />
          <SignInForConference title="sign up for the newsletter" />
        </section>
        <section className="page-container">
          <RigaBitcoinWeek />
          {/* <AgendaList/> */}
        </section>

        <section className="page-container">
          <RigaHotels />
          <Partners />
          <JoinCommunity />
          {/* <ArticleSlider/> */}
        </section>
        <VideoSlider />
      </main>
    </div>
  );
}

export default Main;
