import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";

import diamondUrl from "./../../assets/images/sponsors-icons/diamond.svg";
// import exclusiveSmallUrl from "./../../assets/images/sponsors-icons/exclusive-line.svg";
import soldOutUrl from "./../../assets/images//sponsors-icons/sold_out.svg";
import dimond from "./../../assets/images/sponsors-icons/dimond.svg";

import "./style.scss";
import "swiper/css";
import "swiper/css/pagination";

function EventSlider({ id }) {
  const { t } = useTranslation();
  const events = [
    {
      title: "speakers dinner",
      img: diamondUrl,
      price: "$10 000",
      benefits: "Benefits",
      color: "blue",
      sold: "",
      texts: [
        { one: "Hosted under your brand name" },
        { two: "Invite only" },
        { three: "Branding included" },
      ],
      exclusiveUrl: true,
    },
    {
      title: "Speakers Preparty",
      img: diamondUrl,
      price: "$7 500",
      benefits: "Benefits",
      color: "blue",
      sold: "sold",
      texts: [
        { one: "Hosted under your brand name" },
        { two: "Invite only" },
        { three: "Branding included" },
      ],
      exclusiveUrl: true,
    },
    {
      title: " speakers afterparty",
      img: diamondUrl,
      price: "$10 000",
      benefits: "Benefits",
      color: "blue",
      sold: "",
      texts: [{ one: "Hosted under your brand name" }, { two: "Invite only" }],
      exclusiveUrl: true,
    },
    {
      title: "MAIN Afterparty sponsor",
      img: diamondUrl,
      price: "$10 000",
      benefits: "Benefits",
      color: "blue",
      sold: "sold",
      texts: [
        { one: "Hosted under your brand name" },
        {
          two: (
            <span>
              {t("baltic.honey.openBarWithYourBrandedDrinksMenu")} <br />
              {t("baltic.honey.openBarWithYourBrandedDrinksMenuDrinlk")}
            </span>
          ),
        },
      ],
      exclusiveUrl: true,
    },
    {
      title: "Afterparty sponsor - day 2",
      img: diamondUrl,
      price: "$7 000",
      benefits: "Benefits",
      color: "blue",
      sold: "",
      texts: [
        { one: "Hosted under your brand name" },
        { two: "Welcome drinks" },
      ],
      exclusiveUrl: true,
    },
    {
      title: "THROW Your party",
      img: diamondUrl,
      price: "$5 000",
      benefits: "Benefits",
      color: "blue",
      sold: "",
      texts: [{ one: "Hosted under your brand name" }],
      exclusiveUrl: false,
    },
    {
      title: "Noob day sponsor",
      img: diamondUrl,
      price: "$5 000",
      benefits: "Benefits",
      color: "blue",
      sold: "sold",
      texts: [
        { one: "Hosted under your brand name" },
        { two: "Onsite branding" },
      ],
      exclusiveUrl: true,
    },
    // {
    //   title: "Investor pitch day",
    //   img: diamondUrl,
    //   price: "$3 000",
    //   benefits: "Benefits",
    //   color: "blue",
    //   sold: "sold",
    //   texts: [
    //     { one: "Hosted under your brand name" },
    //     { two: "Onsite branding" },
    //   ],
    //   exclusiveUrl: true,
    // },
    // {
    //   title: "Layer 2 day  sponsor",
    //   img: diamondUrl,
    //   price: "$5 000",
    //   benefits: "Benefits",
    //   color: "blue",
    //   sold: "",
    //   texts: [
    //     { one: "Hosted under your brand name" },
    //     { two: "Onsite branding" },
    //   ],
    //   exclusiveUrl: true,
    // },
    // {
    //   title: "Bitcoin week Workshop",
    //   img: diamondUrl,
    //   price: "$5 000",
    //   benefits: "Benefits",
    //   color: "blue",
    //   sold: "",
    //   texts: [
    //     {
    //       one: (
    //         <span>
    //           {" "}
    //           Host a workshop during <br /> Riga Bitcoin week
    //         </span>
    //       ),
    //     },
    //   ],
    //   exclusiveUrl: true,
    // },
    // {
    //   title: "Riga Bitcoin week sponsor",
    //   img: diamondUrl,
    //   price: "$5 000",
    //   benefits: "Benefits",
    //   color: "blue",
    //   sold: "",
    //   texts: [{ one: "Brand presence on all RBW events" }],
    //   exclusiveUrl: false,
    // },
  ];

  const eventSlides = () =>
    events.map((event) => (
      <div className={clsx("inner-card", event?.sold)} key={event.title}>
        <div className="sold_out">
          <img src={soldOutUrl} alt="soldout" />
          <h2 className="sold_out__title" title="Sold Out">
            {t("baltic.honey.soldOut")}
          </h2>
        </div>
        <div className="element">
          <div className={clsx("inner-card__header", event.color)}>
            <h3 className="card__title">{event.title}</h3>
            <img src={event.img} alt="" />
          </div>
          {event.exclusiveUrl && (
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
          )}
          <div className="card__body">
            <h3 className="card__money">{event.price}</h3>
            <p className="card__list-title">{event.benefits}</p>
            <ul className="card__list">
              {event.texts?.map((text) => (
                <li key={text.one} className="card__elem">
                  {text?.one || text?.two || text?.three}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ));

  const screenWidth = window.screen.width;
  let small = screenWidth < 767;

  return (
    <div className="sponsors-block" id={id}>
      <h2 className="sponsors-block__title">{t("baltic.honey.events")}</h2>
      <p className="sponsors-block__subtitle">
        {t("baltic.honey.becomeTheExclusiveSponsorOfBHAssociatedEvents")}
      </p>
      {small ? (
        <Swiper
          navigation={false}
          pagination={{ clickable: true }}
          modules={[Pagination, A11y]}
          slidesPerView={1}
          spaceBetween={30}
          grabCursor={true}
          className="mySwiper"
        >
          {events.map((event) => (
            <SwiperSlide>
              <div
                className={clsx("inner-card", event?.sold)}
                key={event.title}
              >
                <div className="sold_out">
                  <img src={soldOutUrl} alt="soldout" />
                  <h2 className="sold_out__title" title="Sold Out">
                    {t("baltic.honey.soldOut")}
                  </h2>
                </div>
                <div className="element">
                  <div className={clsx("inner-card__header", event.color)}>
                    <h3 className="card__title">{event.title}</h3>
                    <img src={event.img} alt="" />
                  </div>
                  {event.exclusiveUrl && (
                    <div className="inner-card__exclusive-ofder">
                      <img src={dimond} alt="dimond" />
                      Exclusive offer
                      <img src={dimond} alt="dimond" />
                    </div>
                  )}
                  <div className="card__body">
                    <h3 className="card__money">{event.price}</h3>
                    <p className="card__list-title">{event.benefits}</p>
                    <ul className="card__list">
                      {event.texts?.map((text) => (
                        <li key={text.one} className="card__elem">
                          {text?.one || text?.two || text?.three || text?.four}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="inner-cards events-container">{eventSlides()}</div>
      )}
    </div>
  );
}

export default EventSlider;
