import React from "react";
import { useTranslation } from "react-i18next";

import Subscribe from "./../../components/SignInForConference/SignInForConference";

import "./style.scss";
// import Button from "../../components/Button/Button";

function BuyTickets() {
  const { t } = useTranslation();
  //   const [isShowSubscribe, setShowSubscribe] = useState(false);

  //   const onShowSubscribe = () => {
  //     setShowSubscribe(true);
  //   };

  //   const toTelegram = () => {
  //     window.open("https://t.me/baltichoneybadger", "_blank");
  //   };

  return (
    <section className="buy-tickets">
      <h1 className="buy-tickets__title">2-day BH2024 conference ticket</h1>
      <p className="buy-tickets__label">
        {t("baltic.honey.themostogbitcoinconference")}
      </p>

      <div className="buy-tickets__wrap">
        <h2 className="comic-text">
          {t("baltic.honey.baltic.honeybadger.earlyBirdSale")}
          <br />
          ONLY 200 TICKETS. dON’T MISS IT.
        </h2>
        <Subscribe />

        {/* {isShowSubscribe ? (
          <Subscribe />
        ) : (
          <div className="buy-tickets__buttons">
            <Button
              onClick={onShowSubscribe}
              title="Subscribe to the newsletter"
            />
            <Button onClick={toTelegram} title="Join Telegram" />
          </div>
        )} */}
      </div>
    </section>
  );
}

export default BuyTickets;
