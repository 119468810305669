import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useIsMobile } from "../../hooks/useMediaQuery";
import bitcoinOnly from "./bitcoin-only.webp";
import bestBitcoinSpeakers from "./best-bitcoin-speakers.webp";
import arrowUrl from "../../assets/images/arrow.svg";

import "./style.scss";

function Plan() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <section className="plans">
      {isMobile ? (
        <h2 className="plans__title">
          {t("baltic.honey.cypherpunkmovement")} <br /> for{" "}
          <span className="plans__title__yellow" title="passionate">
            {t("baltic.honey.passionate")}
          </span>{" "}
          <br />{" "}
          <span
            className="plans__title__yellow plans__title__mobile"
            title="Bitcoiners"
          >
            {t("baltic.honey.bitcoiners")}
          </span>
        </h2>
      ) : (
        <h2 className="plans__title">
          {t("baltic.honey.cypherpunk_movement_for")}{" "}
          <span className="plans__title__yellow" title="passionate Bitcoiners">
            {t("baltic.honey.passionate_bitcoiners")}
          </span>
        </h2>
      )}

      <div className="plans__blocks">
        <div className="plans__blocks__block">
          <img
            src={bitcoinOnly}
            alt="Bitcoin-only Conference"
            className="plans__blocks__block__img"
          />
          <h3 className="plans__blocks__block__title">
            {t("baltic.honey.bitcoinonly")}
          </h3>
          <p className="plans__blocks__block__text">
            {t("baltic.honey.importanttopics")}
          </p>
          <Link
            to="/agenda"
            className="plans__blocks__block__link"
            alt="Agenda"
          >
            <p className="plans__blocks__block__link__text">
              {t("baltic.honey.conferenceagenda")}
            </p>
            <img
              src={arrowUrl}
              width="18"
              height="18"
              alt="Agenda"
              className="plans__blocks__block__link__arrow"
            />
          </Link>
        </div>
        <div className="plans__blocks__block">
          <img
            src={bestBitcoinSpeakers}
            alt="Top Best Bitcoin Speakers"
            className="plans__blocks__block__img"
          />
          <h3 className="plans__blocks__block__title">
            {t("baltic.honey.freedomofspeech")}
          </h3>
          <p className="plans__blocks__block__text">
            {t("baltic.honey.latestfreedomtech")}
          </p>
          <Link
            to="/nostriga"
            className="plans__blocks__block__link"
            alt="Speakers"
          >
            <p className="plans__blocks__block__link__text">
              {t("baltic.honey.nostriga")}
            </p>
            <img
              src={arrowUrl}
              width="18"
              height="18"
              alt="Speakers"
              className="plans__blocks__block__link__arrow"
            />
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Plan;
