import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";
// import liveStreamUrl from "./../../assets/images/sponsors-icons/lifestream.svg";
// import wifiUrl from "./../../assets/images/sponsors-icons/wifi.svg";
import coffeeStationUrlUrl from "./../../assets/images/sponsors-icons/coffe-station.svg";
import chillZoneUrl from "./../../assets/images/sponsors-icons/chillZone.svg";
import chargingPointUrl from "./../../assets/images/sponsors-icons/charging-point.svg";
import micStageUrl from "./../../assets/images/sponsors-icons/mic-stage.svg";
import loungeZoneUrl from "./../../assets/images/sponsors-icons/lounge-zone.svg";
import soldOutUrl from "./../../assets/images//sponsors-icons/sold_out.svg";
import exclusiveSmallUrl from "./../../assets/images/sponsors-icons/exclusive-line.svg";
import dimond from "./../../assets/images/sponsors-icons/dimond.svg";
import { useIsMobile } from "../../hooks/useMediaQuery";

import "./style.scss";
import "swiper/css";
import "swiper/css/pagination";

function ConferenceFacilities({ id }) {
  const { t } = useTranslation();
  const facilities = [
    {
      title: "Charging point",
      img: chargingPointUrl,
      price: "$5 000",
      benefits: "Benefits",
      color: "red",
      sold: "sold",
      texts: [
        {
          one: (
            <span>
              {t("baltic.honey.yourBrandedCharging")} <br />
              stations around the venue
            </span>
          ),
        },
      ],
      exclusiveUrl: exclusiveSmallUrl,
    },
    {
      title: "Coffee station",
      img: coffeeStationUrlUrl,
      price: "$10 000",
      benefits: "Benefits",
      color: "red",
      sold: "",
      texts: [
        { one: "Unlimited coffee for everyone" },
        { two: "Customised coffee cups" },
        { three: "Branding" },
      ],

      exclusiveUrl: exclusiveSmallUrl,
    },
    {
      title: "Cypherpunk stage branding",
      img: micStageUrl,
      price: "$8 000",
      benefits: "Benefits",
      color: "red",
      sold: "sold",
      texts: [
        {
          one: "Cypherpunk stage featured under your brand name",
        },
        { two: "Your promo in stage recordings" },
        { three: "Branding" },
      ],
      exclusiveUrl: exclusiveSmallUrl,
    },
    {
      title: "Chill zone branding",
      img: chillZoneUrl,
      price: "$6 000",
      benefits: "Benefits",
      color: "red",
      sold: "",
      texts: [
        {
          one: (
            <span>
              {t("baltic.honey.oneOfTheMainConference")} <br /> facilities
              featured under <br /> your brand name
            </span>
          ),
        },
        {
          two: "Branding",
        },
      ],
      exclusiveUrl: exclusiveSmallUrl,
    },
    {
      title: "Your BRANDED lounge",
      img: loungeZoneUrl,
      price: "$7 500",
      benefits: "Benefits",
      color: "red",
      sold: "sold",
      texts: [
        {
          one: (
            <span>
              {t("baltic.honey.hostYourOwnLounge")}
              <br /> during the conference
            </span>
          ),
        },
        { two: "Custom setup prepared by us" },
      ],
      exclusiveUrl: exclusiveSmallUrl,
    },
  ];

  const facilitiesSlides = () => (
    // facilities.map((facility) => (
    <>
      <div className="caonference-first-box">
        <div className="inner-card sold">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
            <h2 className="sold_out__title" title="Sold Out">
              Sold Out
            </h2>
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">{t("baltic.honey.chargingPoint")}</h3>
              <img src={chargingPointUrl} alt="chargingPointUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$5 000</h3>
              <p className="card__list-title">{t("baltic.honey.benefits")}</p>
              <ul className="card__list">
                {[
                  {
                    one: (
                      <span>
                        {t("baltic.honey.yourBrandedCharging")} <br />
                        stations around the venue
                      </span>
                    ),
                  },
                ]?.map((text) => (
                  <li className="card__elem">
                    {text?.one || text?.two || text?.three}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="inner-card">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
            <h2 className="sold_out__title" title="Sold Out">
              Sold Out
            </h2>
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">{t("baltic.honey.coffeeStation")}</h3>
              <img src={coffeeStationUrlUrl} alt="chillZoneUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$10 000</h3>
              <p className="card__list-title">{t("baltic.honey.benefits")}</p>
              <ul className="card__list">
                {[
                  { one: "Unlimited coffee for everyone" },
                  { two: "Customised coffee cups" },
                  { three: "Branding" },
                ]?.map((text) => (
                  <li className="card__elem">
                    {text?.one || text?.two || text?.three}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="caonference-second-box">
        <div className="inner-card sold">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
            <h2 className="sold_out__title" title="Sold Out">
              Sold Out
            </h2>
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">
                {t("baltic.honey.cypherpunkStageBranding")}
              </h3>
              <img src={micStageUrl} alt="micStageUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$8 000</h3>
              <p className="card__list-title">{t("baltic.honey.benefits")}</p>
              <ul className="card__list">
                {[
                  {
                    one: "Cypherpunk stage featured under your brand name",
                  },
                  { two: "Your promo in stage recordings" },
                  { three: "Branding" },
                ]?.map((text) => (
                  <li className="card__elem">
                    {text?.one || text?.two || text?.three}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="inner-card">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
            <h2 className="sold_out__title" title="Sold Out">
              Sold Out
            </h2>
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">
                {t("baltic.honey.chillZoneBranding")}
              </h3>
              <img src={chillZoneUrl} alt="chillZoneUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$6 000</h3>
              <p className="card__list-title">{t("baltic.honey.benefits")}</p>
              <ul className="card__list">
                {[
                  {
                    one: (
                      <span>
                        {t("baltic.honey.oneOfTheMainConference")} <br />{" "}
                        facilities featured under <br /> your brand name
                      </span>
                    ),
                  },
                  {
                    two: "Branding",
                  },
                ]?.map((text) => (
                  <li className="card__elem">
                    {text?.one || text?.two || text?.three}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="inner-card sold">
          <div className="sold_out">
            <img src={soldOutUrl} alt="" />
            <h2 className="sold_out__title" title="Sold Out">
              Sold Out
            </h2>
          </div>
          <div className="element">
            <div className="inner-card__header red">
              <h3 className="card__title">
                {t("baltic.honey.yourBrandedLounge")}
              </h3>
              <img src={loungeZoneUrl} alt="chillZoneUrl" />
            </div>
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
            <div className="card__body">
              <h3 className="card__money">$7 500</h3>
              <p className="card__list-title">{t("baltic.honey.benefits")}</p>
              <ul className="card__list">
                {[
                  {
                    one: (
                      <span>
                        {t("baltic.honey.hostYourOwnLounge")} <br /> during the
                        conference
                      </span>
                    ),
                  },
                  { two: "Custom setup prepared by us" },
                ]?.map((text) => (
                  <li className="card__elem">
                    {text?.one || text?.two || text?.three}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // ));

  let small = useIsMobile();

  return (
    <div className="sponsors-block" id={id}>
      <h2 className="sponsors-block__title">
        {t("baltic.honey.conferenceFacilities")}
      </h2>
      <p className="sponsors-block__subtitle">
        {t("baltic.honey.exclusiveOffersForBHConferenceFacilityBranding")}
      </p>

      {small ? (
        <Swiper
          navigation={false}
          pagination={{ clickable: true }}
          modules={[Pagination, A11y]}
          slidesPerView={1}
          spaceBetween={30}
          grabCursor={true}
          className="mySwiper"
        >
          {facilities.map((facility) => (
            <SwiperSlide>
              <div
                className={clsx("inner-card", facility?.sold)}
                key={facility?.title}
              >
                <div className="element">
                  <div className="sold_out">
                    <img src={soldOutUrl} alt="" />
                    <h2 className="sold_out__title" title="Sold Out">
                      Sold Out
                    </h2>
                  </div>
                  <div className={clsx("inner-card__header", facility.color)}>
                    <h3 className="card__title">{facility.title}</h3>
                    <img src={facility.img} alt="" />
                  </div>
                  <div className="inner-card__exclusive-ofder">
                    <img src={dimond} alt="dimond" />
                    Exclusive offer
                    <img src={dimond} alt="dimond" />
                  </div>
                  <div className="card__body">
                    <h3 className="card__money">{facility.price}</h3>
                    <p className="card__list-title">{facility.benefits}</p>
                    <ul className="card__list">
                      {facility.texts?.map((text) => (
                        <li key={text.one} className="card__elem">
                          {text?.one || text?.two || text?.three || text?.four}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className={`${small && "inner-cards rows-2"}`}>
          {facilitiesSlides()}
        </div>
      )}
    </div>
  );
}

export default ConferenceFacilities;
