import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useIsMobile } from "../../hooks/useMediaQuery";
import balticHoneybadger from "../../assets/images/Honeybadger_desktop.webp";
import bitcoinConferenceTickets from "./../../assets/images/sponsors-icons/bitcoin-conference-tickets.svg";

import "./conferenceTickets.scss";
import ConferenceTicketButton from "./ConferenceTicketButton";
import {ConferenceTicketsBanner} from "./ConferenceTicketsBanner";

function ConferenceTickets() {
  const {t} = useTranslation();
  const mobile = useIsMobile();
  const isClosed = true;

  if (isClosed) {
    return (
        <section className="conference-tickets">
          <Helmet>
            <title>{t("baltic.honey.buyTicketsTitle")}</title>
            <meta
                name="description"
                content="Buy Tickets for the Baltic Honeybadger Conference. 2 day conference ticket, food and drinks included."
            />
          </Helmet>
          <div className="conference-tickets__container">
            <h1 style={{visbility: "hidden", fontSize: "0"}}>
              {t("baltic.honey.conferenceTicketTitle")}
            </h1>
            <h2
                className="conference-tickets__title"
                title="2-day BH2024 Conference  Ticket"
            >
              2-day BH2024 Conference Ticket
            </h2>
            <p className="conference-tickets__subtitle">
              {t("baltic.honey.themostogbitcoinconference")}
            </p>
          </div>

            <ConferenceTicketsBanner
                title={t('Thank you for participating')}
                text={t('Watch the recorded livestream from the BH2024 main stage')}
                url={'https://www.youtube.com/live/V48aU2UUV68?si=jjITDdkQjWHz9ZMY'}
                urlText={'Baltic Honeybadger Youtube'}
            />

          <img
              alt="Buy tickets to Baltic Honeybadger 2024 conference"
              src={balticHoneybadger}
              className="conference-tickets__image"
          />
        </section>
    )
  }

  return (
      <section className="conference-tickets">
        <Helmet>
          <title>{t("baltic.honey.buyTicketsTitle")}</title>
          <meta
              name="description"
              content="Buy Tickets for the Baltic Honeybadger Conference. 2 day conference ticket, food and drinks included."
          />
        </Helmet>
        <div className="conference-tickets__container">
          <h1 style={{visbility: "hidden", fontSize: "0"}}>
            {t("baltic.honey.conferenceTicketTitle")}
          </h1>
          <h2
              className="conference-tickets__title"
              title="2-day BH2024 Conference  Ticket"
          >
            2-day BH2024 Conference Ticket
          </h2>
          <p className="conference-tickets__subtitle">
            {t("baltic.honey.themostogbitcoinconference")}
          </p>
          <div className="conference-tickets__card">
            <div className="card__header">
              <div className="card__about-price">
                <span className="card__price">€300</span> /
                {mobile ? "GA" : "General Admission"}
                <ul className="card__list">
                  {/* <li className="card__elem">Food and drinks</li> */}
                  <li className="card__elem">{`Full access ${
                      !mobile ? "to all conference facilities" : ""
                  }`}</li>
                  <li className="card__elem">{`${
                      !mobile ? "Access to the " : ""
                  }Riga Bitcoin Week events`}</li>
                  <li className="card__elem">{`Goodie bag ${
                      !mobile ? " with conference merchandise" : ""
                  }`}</li>
                </ul>
              </div>
              <img
                  src={bitcoinConferenceTickets}
                  alt="Buy tickets to bitcoin 2024 conference"
                  width="102"
                  height="102"
                  className="card__img"
              />
            </div>
            <div className="card__buttons">
              <ConferenceTicketButton/>
            </div>
            <div className="card__tandc">
              {t("baltic.honey.termsAndConditions")}{" "}
              <Link className="card__tandc__link" to="/terms-conditions">
                T&C
              </Link>
            </div>
          </div>
        </div>

        <img
            alt="Buy tickets to Baltic Honeybadger 2024 conference"
            src={balticHoneybadger}
            className="conference-tickets__image"
        />
    </section>
  );
}

export default ConferenceTickets;
