import React from "react";
import { useTranslation } from "react-i18next";

import { useIsMobile, useIsTablet } from "../../hooks/useMediaQuery";
import rigaHotels from "./riga-hotels.webp";
import rijaHotels from "./rija-hotels.svg";
import welltonHotels from "./wellton-hotels.svg";
import rixwellHotels from "./rixwell-hotels.svg";

import "../RigaBitcoinWeek/style.scss";

function RigaHotels({ revertDirection = false }) {
  const { t } = useTranslation();
  let tablet = useIsTablet();
  let mobile = useIsMobile();

  return (
    <div
      style={revertDirection ? { flexDirection: "row-reverse" } : {}}
      className="rigaHotels"
    >
      <div className="rigaHotels__content">
        {tablet && (
          <h2
            className="rigaHotels__content__title"
            title="PARTNERING HOTELS IN RIGA"
          >
            {t("baltic.honey.partneringhotels")}
          </h2>
        )}
        <img
          alt="Hotels to stay in Riga"
          src={rigaHotels}
          className="rigaHotels__content__img"
        />
        {tablet && !mobile && (
          <div className="rigaHotels__content__logos">
            <a
              href="https://www.wellton.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Wellton Hotels Riga"
                src={welltonHotels}
                className="rigaHotels__content__logos__img"
              />
            </a>
            <a
              href="https://www.rijahotels.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Rija Hotels Riga"
                src={rijaHotels}
                className="rigaHotels__content__logos__img"
              />
            </a>
            <a
              href="https://www.rixwell.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Rixwell Hotels Riga"
                src={rixwellHotels}
                className="rigaHotels__content__logos__img"
              />
            </a>
          </div>
        )}
        {tablet && (
          <p className="rigaHotels__content__text">
            {t("baltic.honey.accommodation")}
            <br />
            <br />
            {t("baltic.honey.promocodeuse")}{" "}
            <span className="rigaHotels__content__text__bolder">
              {t("baltic.honey.promocodebh2024")}
            </span>{" "}
            {t("baltic.honey.promocodeend")}
          </p>
        )}
        {mobile && (
          <div className="rigaHotels__content__logos">
            <a
              href="https://www.wellton.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Wellton Hotels Riga"
                src={welltonHotels}
                className="rigaHotels__content__logos__img"
              />
            </a>
            <a
              href="https://www.rijahotels.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Rija Hotels Riga"
                src={rijaHotels}
                className="rigaHotels__content__logos__img"
              />
            </a>
            <a
              href="https://www.rixwell.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Rixwell Hotels Riga"
                src={rixwellHotels}
                className="rigaHotels__content__logos__img"
              />
            </a>
          </div>
        )}
      </div>
      {!tablet && (
        <div className="rigaHotels__content">
          <h2
            className="rigaHotels__content__title"
            title="PARTNERING HOTELS IN RIGA"
          >
            {t("baltic.honey.partneringhotels")}
          </h2>
          <p className="rigaHotels__content__text">
            {t("baltic.honey.accommodation")}
            <br />
            <br />
            {t("baltic.honey.promocodeuse")}{" "}
            <span className="rigaHotels__content__text__bolder">
              {t("baltic.honey.promocodebh2024")}
            </span>{" "}
            {t("baltic.honey.promocodeend")}
          </p>
          <div className="rigaHotels__content__logos">
            <a
              href="https://www.wellton.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Wellton Hotels Riga"
                src={welltonHotels}
                className="rigaHotels__content__logos__img"
              />
            </a>
            <a
              href="https://www.rijahotels.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Rija Hotels Riga"
                src={rijaHotels}
                className="rigaHotels__content__logos__img"
              />
            </a>
            <a
              href="https://www.rixwell.com/en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Rixwell Hotels Riga"
                src={rixwellHotels}
                className="rigaHotels__content__logos__img"
              />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default RigaHotels;
