import React from "react";
import "./bitcoinWeek.scss";
import { useIsMobile } from "../../hooks/useMediaQuery";

const Days = (props) => {
  const {
    title,
    subtitle,
    color,
    headTitle,
    headSubtitle,
    ticket,
    imageSrc,
    imageSmSrc = imageSrc,
    imageAlt,
    imageClass = "",
    background = "",
    location = "TBA",
    clock = "TBA",
    buttons = [],
    className = "",
    infoTitle,
    infoDescription = "To be announced soon",
  } = props;

  const mobile = useIsMobile();
  const imageUrl = mobile ? imageSmSrc : imageSrc;
  const headImageClass = imageUrl
    ? `days-block__head--image ${imageClass}`
    : "";

  return (
    <div className={`days ${className}`}>
      {title && <h2 className="days__title">{title}</h2>}
      {subtitle && <p className="days__subtitle">{subtitle}</p>}
      <div className={`days-block ${background}`}>
        <div className={`days-block__head ${color} ${headImageClass}`}>
          <h3 className="days-block__head__title">{headTitle}</h3>
          <h3 className="days-block__head__subtitle">{headSubtitle}</h3>
          <div className="days-block__head__list">
            {ticket && (
              <p className="days-block__head-item days-block__head-item--ticket ticket-color">
                {ticket}
              </p>
            )}
            <p className="days-block__head-item days-block__head-item--location location-color">
              {location}
            </p>
            <p
              className={`days-block__head-item days-block__head-item--clock clock-color ${
                buttons?.length === 0 ? "no-space" : ""
              }`}
            >
              {clock}
            </p>
            {buttons?.map((button) => (
              <a
                key={button.id}
                className="days-block__head__button"
                target="_blank"
                rel="noreferrer"
                href={button.path}
              >
                {button.title}
              </a>
            ))}
          </div>
        </div>
        <div className="days-block__info">
          <h3 className="days-block__info__title">{infoTitle}</h3>
          <p className="days-block__info__description">{infoDescription}</p>
          {buttons?.map((button) => (
            <a
              key={button.id}
              className="days-block__info__button"
              href={button.path}
            >
              {button.title}
            </a>
          ))}
          <img
            className={`info-image ${imageClass}`}
            src={imageSrc}
            alt={imageAlt}
          />
        </div>
      </div>
    </div>
  );
};

export default Days;
