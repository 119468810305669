import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";

import standardBoothUrl from "./../../assets/images/sponsors-icons/standart-booth.svg";
import premiumBoothUrl from "./../../assets/images/sponsors-icons/honey-booth.svg";
import soldOutUrl from "./../../assets/images/sponsors-icons/sold_out.svg";

import "./style.scss";
import "slick-slider/slick/slick.css";
import "slick-slider/slick/slick-theme.css";
import "swiper/css";
import "swiper/css/pagination";

function ExhibitorBooth({ id }) {
  const { t } = useTranslation();
  const boothes = [
    {
      title: "Standard Booth",
      subtitle: <span>{t("baltic.honey.space_in_our_exhibit_hall")}</span>,
      img: standardBoothUrl,
      price: "$3 500",
      benefits: "Benefits",
      color: "red",
      sold: "",
      texts: [
        { one: "Space for your setup" },
        { two: "Furniture" },
        { three: "2 GA passes" },
      ],
    },
    {
      title: "Honeybadger Booth",
      subtitle: <span>{t("baltic.honey.booth_with_custom_BH_setup")}</span>,
      img: premiumBoothUrl,
      price: "$7 000",
      benefits: "Benefits",
      color: "red",
      sold: "",
      texts: [
        { one: "Custom BH setup" },
        { two: "Prepared for you" },
        { three: "2 GA passes" },
      ],
    },
    {
      title: "FOSS Booth",
      subtitle: <span>{t("baltic.honey.space_in_our_exhibit_hall")}</span>,
      isForFoss: true,
      img: premiumBoothUrl,
      price: "$1 500",
      benefits: "Benefits",
      color: "orange",
      sold: "",
      texts: [{ one: "Space for your setup" }, { two: "Furniture" }],
    },
  ];

  const boothesSlides = () =>
    boothes.map((booth) => (
      <div className={clsx("inner-card", booth?.sold)}>
        <div className="sold_out">
          <img src={soldOutUrl} alt="soldout" />
          <h2 className="sold_out__title" title="Sold Out">
            {t("baltic.honey.soldOut")}
          </h2>
        </div>
        <div className="element">
          <div className={clsx("inner-card__header", booth.color)}>
            <h3 className="card__title">{booth.title}</h3>
          </div>
          {booth.isForFoss && (
            <div className="inner-card__exclusive-ofder">
              {t("baltic.honey.only_for_foss_projects")}
            </div>
          )}
          <div className="card__body">
            <h3 className="card__money">{booth.price}</h3>
            <p className="card__description">{booth.subtitle}</p>
            <p className="card__list-title">{booth.benefits}</p>
            <ul className="card__list">
              {booth.texts?.map((text) => (
                <li key={text.one} className="card__elem">
                  {text?.one || text?.two || text?.three || text?.four}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ));

  const screenWidth = window.screen.width;
  let small = screenWidth < 767;

  return (
    <div className="sponsors-block" id={id}>
      <h2 className="sponsors-block__title">
        {t("baltic.honey.exhibitorBooths")}
      </h2>
      <p className="sponsors-block__subtitle">
        {t("baltic.honey.engageAttendeesAtYourBoothDuringBothConferenceDays")}
      </p>

      {small ? (
        <Swiper
          navigation={false}
          pagination={{ clickable: true }}
          modules={[Pagination, A11y]}
          slidesPerView={1}
          spaceBetween={30}
          grabCursor={true}
          className="mySwiper"
        >
          {boothes.map((booth) => (
            <SwiperSlide>
              <div className={clsx("inner-card", booth?.sold)}>
                <div className="sold_out">
                  <img src={soldOutUrl} alt="soldout" />
                  <h2 className="sold_out__title" title="Sold Out">
                    {t("baltic.honey.soldOut")}
                  </h2>
                </div>
                <div className="element">
                  <div className={clsx("inner-card__header", booth.color)}>
                    <h3 className="card__title">{booth.title}</h3>
                    {/* <img src={booth.img} alt="" /> */}
                  </div>
                  {/* <div className="inner-card__exclusive-ofder">
                    <img src={dimond} alt="dimond" />
                    Exclusive offer
                    <img src={dimond} alt="dimond" />
                  </div> */}
                  <div className="card__body">
                    <h3 className="card__money">{booth.price}</h3>
                    <p className="card__description">{booth.subtitle}</p>
                    <p className="card__list-title">{booth.benefits}</p>
                    <ul className="card__list">
                      {booth.texts?.map((text) => (
                        <li key={text.one} className="card__elem">
                          {text?.one || text?.two || text?.three || text?.four}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="inner-cards">{boothesSlides()}</div>
      )}
    </div>
  );
}

export default ExhibitorBooth;
