export function cn(...args) {
    return args.filter(Boolean).join(' ')
}

function cnmod(key, value, delim = '_') {
    if (typeof value === 'boolean') {
        if (value) {
            return key
        }

        return ''
    }

    return `${key}${delim}${value}`
}

export function cnmods(mods, options = { modDelim: '--', valDelim: '_' }) {
    const { modDelim, valDelim } = options
    const values = Object.entries(mods).map(([key, value]) => cnmod(key, value, valDelim))

    return function cnmodsblock(block = '') {
        if (block) {
            return cn(block, ...values.map(mod => `${block}${modDelim}${mod}`))
        }

        return cn(...values)
    }
}

/**
 * @example
 * const cn = cnblock('block')
 * cn() // block
 * cn({ foo: 'bar', bar: true }) // block block--foo_bar block--bar
 * cn('element') // block__element
 * cn('element', { foo: 'bar', bar: true }) // block__element block__element--foo_bar block__element--bar
 */
export function cnblock(block, options = { elDelim: '__', modDelim: '--', valDelim: '_' }) {
    const { elDelim } = options

    return function cnelement(element = '', mods) {
        if (typeof element === 'object' && element !== null) {
            return cn(cnmods(element, options)(block))
        }

        if (!element) {
            return cn(block)
        }

        if (typeof mods === 'object' && mods !== null) {
            return cn(cnmods(mods, options)(`${block}${elDelim}${element}`))
        }

        return cn(`${block}__${element}`)
    }
}
