import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import Days from "./Days.jsx";
import btcpayserverday from "./btcpayserverday.svg";
import nostrNew from "./nostrNew.png";
import nostrNewMobile from "./nostrNewMobile.png";
import glasses from "./glasses.png";

import { useIsMobile } from "../../hooks/useMediaQuery.js";

import "./bitcoinWeek.scss";

const BitcoinWeek = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <div className="bitcoin-week">
      <Helmet>
        <title>{t("baltic.honey.rigaweek.title")}</title>
        <meta
          name="description"
          content="Riga Bitcoin Week schedule, a week of high-quality educational content delivered by the Top Bitcoin Speakers."
        />
      </Helmet>
      <div className="bitcoin-week__container">
        <h1 className="bitcoin-week__title" title="Riga Bitcoin week 2024">
          {t("baltic.honey.rigaWeek.subtitle")}
        </h1>
        <p className="bitcoin-week__subtitle">
          {t("baltic.honey.rigaweek.educationalcontent")}
        </p>
      </div>
      <Days
        className="noob-day"
        title="August 21st"
        headTitle="NOOB day"
        ticket="Free entrance"
        infoTitle="Introduction to Bitcoin"
        infoDescription="Dive into the essential topics of Bitcoin: self-custody, security, privacy, economics, and more. Join us for interactive sessions covering peer-to-peer, philosophy, and the history of Bitcoin. Whether you're an experienced Bitcoin enthusiast or a Noob - Noob Day is crafted to meet the curiosity and knowledge-seeking desires of all participants."
        color="blue"
        location={
          <a href="https://g.co/kgs/Y6TL9bV" target="_blank" rel="noreferrer">
            Spikeri Concert Hall
          </a>
        }
        clock="10:00 - 17:00"
        subtitle="The opening of the Riga Bitcoin Week - Bitcoin Infinity Noob day edition"
      />
      <Days
        className="twentyone"
        headTitle="Infinity Day Party"
        ticket="Free entrance"
        location={
          <a href="https://g.co/kgs/Ru22ULL" target="_blank" rel="noreferrer">
            Basement bar
          </a>
        }
        background="all-black"
        clock="18:00"
        infoDescription="To mark the annual Bitcoin Infinity Day holiday (8/21), join Knut Svanholm and friends at Riga's pre-eminent Bitcoin bar, and celebrate the Bitcoin's infinite potential. Join to kick off Riga Bitcoin Week in style!"
      />
      {/* <Days
        headTitle="spanish meetup"
        ticket="Free entrance"
        infoTitle="Spanish Bitcoin community meetup"
        infoDescription="We are welcoming the Spanish-speaking Bitcoin community in Riga. Attend the event if you speak Spanish and want to meet local community representatives."
        color="red"
        location={
          <a
            href="https://maps.app.goo.gl/R1UQN21oNndMq4ZEA?g_st=ic"
            target="_blank"
            rel="noreferrer"
          >
            Basement bar
          </a>
        }
        clock="18:00 - 22:00"
      /> */}
      {/* <Days
        headTitle="RaspiBlitz Meetup"
        ticket="Free entrance"
        infoTitle="RaspiBlitz community meetup"
        infoDescription="Whether you're a dedicated RaspiBlitz full node runner or simply someone who appreciates the power of Open-Source collaboration, join the meetup for talks and discussions of the project's current issues and future developments. Hosted by: rootzoll & openoms"
        color="yellow"
        location={
          <a
            href="https://goo.gl/maps/3UDGjzW3BZkLik669"
            target="_blank"
            rel="noreferrer"
          >
            OraculeTang Space
          </a>
        }
        clock="18:00 - 22:00"
      /> */}
      {/* <Days
        className="pitch-competition"
        headTitle="pitch COMPETITION"
        headSubtitle="by Fulgur Ventures"
        ticket="BH2023 ticket holders"
        infoTitle="Are you building a Bitcoin startup?"
        infoDescription={pitchDescription}
        location={
          <a
            href="https://goo.gl/maps/X3ANgcChs4SqJPzUA"
            target="_blank"
            rel="noreferrer"
          >
            Gravity Team office
          </a>
        }
        color="red"
        clock="18:00 - 22:00"
        buttons={[
          {
            title: "Submit application",
            path: "https://docs.google.com/forms/d/e/1FAIpQLSeX69C1OIkPoz2TxT8ZmTQ29Nv3tsaTPuKwAO47TiV4hs6Q0w/viewform?usp=sf_link",
          },
        ]}
      /> */}

      <Days
        className="layer"
        title="August 22-23rd"
        headTitle="NOSTRIGA"
        ticket="Ticket required"
        infoTitle="Freedom Unconference"
        infoDescription="Nostriga is an event organized by Nostr.world community brought to you by Jack and the
        team of volunteers. Attend the 2 day event to learn about Nostr, a decentralized social media protocol, and the tools around it. A free conference, bringing together freedom enthusiasts from different corners of the globe for conversations on everything nostr."
        color="blue"
        location={
          <a
            href="https://goo.gl/maps/ZjxfrbeP2JhHDbAi6"
            target="_blank"
            rel="noreferrer"
          >
            {t("baltic.honey.rigaweek.location")}
          </a>
        }
        clock="10:00 - 18:00"
        buttons={[
          {
            id: 1,
            title: "GET FREE TICKET",
            path: "https://tally.so/r/wbdMlL",
          },
        ]}
        subtitle="Two day extension to the Baltic Honeybadger conference, join us and learn about Nostr - freedom of social media"
        imageAlt="lights"
        imageSrc={isMobile ? nostrNewMobile : nostrNew}
        imageClass="lights"
      />
      <Days
        className="twentyone"
        headTitle="Karaoke night"
        ticket="Nostriga ticket holders"
        location={
          <a
            href="https://goo.gl/maps/ZjxfrbeP2JhHDbAi6"
            target="_blank"
            rel="noreferrer"
          >
            Fantadroms
          </a>
        }
        background="all-black"
        clock="22/08 at 18:00"
        infoDescription="Elevate your karaoke game with us! Experience a whole new level of fun with our professional stage setup and Nostriches by your side. Get ready to sing your heart out and create unforgettable memories!"
      />
      {/* <Days
        className="anita"
        headTitle="Anita Posch Meetup"
        ticket="Free entrance"
        infoTitle="For everyone interested in Bitcoin as a freedom tool "
        infoDescription="The meet-up will focus on Bitcoin as a freedom tool, Human Rights, and building communities in Africa - inviting people to listen to Anita's experiences in education (with a focus on Africa) and share theirs."
        color="blue"
        location={
          <a
            href="https://maps.app.goo.gl/totJzgSPg5y5FSpG7?g_st=ic"
            target="_blank"
            rel="noreferrer"
          >
            Zefirs
          </a>
        }
        clock="18:00 - 19:30"
      /> */}

      <Days
        className="layer"
        title="August 24-25th"
        subtitle="The culmination of Riga Bitcoin Week - one and only Baltic Honeybadger "
        headTitle="BH2024"
        location={
          <a
            href="https://goo.gl/maps/ZjxfrbeP2JhHDbAi6"
            target="_blank"
            rel="noreferrer"
          >
            {t("baltic.honey.rigaweek.location")}
          </a>
        }
        clock="10:00 - 18:00"
        ticket="Ticket required"
        color="blue"
        imageAlt="Baltic Honeybadger"
        imageClass="baltic-mining"
        imageSrc={glasses}
        buttons={[
          // { id: 1, title: "Buy tickets", path: "/buy-tickets" },
          // { id: 2, title: "GET Directions ", path: direction },
        ]}
        infoTitle="Baltic Honeybadger Conference"
        infoDescription="Legendary Baltic Honeybadger, the first and the most OG Bitcoin-only event in the world. Two days full of content, we talk about Bitcoin, freedom and much more—the most essential conversations behind closed doors. Presentations, panels, and fireside chats about the most crucial and controversial topics in Bitcoin held on 3 stages."
      />
      <Days
        className="twentyone"
        headTitle="MAIN AFTERPARTY"
        ticket="BH ticket holders"
        location={
          <a
            href="https://goo.gl/maps/ZjxfrbeP2JhHDbAi6"
            target="_blank"
            rel="noreferrer"
          >
            Fantadroms
          </a>
        }
        background="all-black"
        clock="24/08 at 18:00"
        infoDescription={
          <>
            Join us for the ultimate afterparty experience! Enjoy an open bar
            brought to you by{" "}
            <a href="https://stakwork.ai/" target="_blank" rel="noreferrer">
              Stak
            </a>{" "}
            and your favorite Baltic Honeybadger DJ. Connect, unwind, and have
            fun with your fellow Bitcoiners. We've prepared some exciting
            activities for you!
          </>
        }
      />
      {/* <Days
        className="mainafterparty"
        headTitle="MAIN AFTERPARTY"
        ticket="BH2023 ticket holders"
        infoTitle="Official afterparty"
        location={
          <a
            href="https://goo.gl/maps/ZjxfrbeP2JhHDbAi6"
            target="_blank"
            rel="noreferrer"
          >
            Fantadroms
          </a>
        }
        background="all-black"
        clock="18:00 - 24:00"
        infoDescription="An afterparty for all Baltic Honeybadger attendees, where you can network, meet the speakers in an informal atmosphere and enjoy the music delivered by bitcoiners and famous BH DJ - All-viss!"
      /> */}
      {/* <Days
        className="conferance2"
        title="September 3rd"
        headTitle="Conference DAY 2"
        location={
          <a
            href="https://goo.gl/maps/ZjxfrbeP2JhHDbAi6"
            target="_blank"
            rel="noreferrer"
          >
            Fantadroms
          </a>
        }
        clock="10:00 - 18:00"
        color="black"
        imageAlt="Baltic Honeybadger"
        imageClass="baltic-mining"
        imageSrc={mining}
        buttons={[
          // { id: 1, title: "Buy tickets", path: "/buy-tickets" },
          { id: 2, title: "GET Directions ", path: direction },
        ]}
        infoTitle="Baltic Honeybadger"
        infoDescription="Presentations, panels, and fireside chats about the most crucial and controversial topics in Bitcoin, a day full of content on 2 stages, workshops, and demos!"
      />
      <Days
        className="nostrparty"
        headTitle="NOSTR PARTY"
        ticket="BH2023 ticket holders"
        infoTitle="What is a Nostr party?"
        infoDescription="Nostr is a simple, open protocol that enables global, decentralized, and censorship-resistant social media.
              Attend the event to learn more about nostr and meet people building nostr community!"
        color="black-purple"
        background="all-black"
        imageAlt="Nostr"
        imageClass="nostr"
        clock="20:00"
        imageSrc={nostr}
        imageSmSrc={nostrsm}
        location={
          <a
            href="https://maps.app.goo.gl/totJzgSPg5y5FSpG7?g_st=ic"
            target="_blank"
            rel="noreferrer"
          >
            Zefirs
          </a>
        }
      /> */}
      <Days
        participants
        className="hosted btcpay"
        title="August 26th"
        subtitle="Traditional Riga Bitcoin Week closing event - BTCPay Server supporters get together "
        headTitle="BTCPAY DAY"
        ticket={
          <a
            href="https://day.btcpayserver.org"
            target="_blank"
            rel="noreferrer"
          >
            Proof of merch
          </a>
        }
        color="blue"
        location={
          <a href="https://g.co/kgs/XG3siSm" target="_blank" rel="noreferrer">
            K. K. fon Stricka villa
          </a>
        }
        buttons={[
          {
            id: 1,
            title: "REGISTER",
            path: "https://day.btcpayserver.org/",
          },
        ]}
        imageSrc={btcpayserverday}
        imageAlt="BTC pay server day"
        clock="Opening at 12:30"
        infoTitle="Free Open-Source Self-hosted Payment Processor"
        infoDescription="BTCPay Server is one of the most essential tools in the Bitcoin space. Baltic Honeybadger and thousands of other economies in the space are fully running on BTCPay. Attend the event and learn how to build your self-sovereign Bitcoin economy on top of BTCPay."
      />
      {/* <AnnouncedSoon
        lastYearLink="https://2023.baltichoneybadger.com/riga-bitcoin-week"
        lastYearTitle="baltic honeybadger 2023 week"
        subtitle="Have you seen the presentations from the previous year?"
        title="Riga Bitcoin Week will be announced soon!"
      />
      <VideoSlider /> */}
    </div>
  );
};

export default BitcoinWeek;
