import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import rigaBitcoinWeek from "./riga-bitcoin-week.webp";
import { useIsTablet } from "../../hooks/useMediaQuery";

import "./style.scss";

function RigaBitcoinWeek({ revertDirection = false }) {
  const { t } = useTranslation();
  let tablet = useIsTablet();

  return (
    <div
      style={revertDirection ? { flexDirection: "row-reverse" } : {}}
      className={"bitcoinWeek"}
    >
      <div className="bitcoinWeek__content">
        <h2 className="bitcoinWeek__content__title" title="Riga bitcoin week">
          {t("baltic.honey.rigabitcoinweek")}
        </h2>
        {tablet && (
          <img
            alt="Top Best Bitcoin Speakers"
            src={rigaBitcoinWeek}
            className="bitcoinWeek__content__img"
          />
        )}
        <p className="bitcoinWeek__content__text">
          {t("baltic.honey.highqualitycontent")}
        </p>
        <p className="bitcoinWeek__content__text">
          {t("baltic.honey.rbwfocus")}
        </p>
        <button className="bitcoinWeek__button">
          <Link
            to="/riga-bitcoin-week"
            className="bitcoinWeek__button__link"
            alt="Buy Tickets"
          >
            {t("baltic.honey.learnmore")}
          </Link>
        </button>
      </div>
      {!tablet && (
        <div className="bitcoinWeek__content">
          <img
            alt="Riga"
            src={rigaBitcoinWeek}
            className="bitcoinWeek__content__img"
          />
        </div>
      )}
    </div>
  );
}

export default RigaBitcoinWeek;
