import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import digitalBrandingUrl from "./../../assets/images/sponsors-icons/digital-branding.svg";
// import digitalTicketUrl from "./../../assets/images/sponsors-icons/digital-ticket-branding.svg";
import liveStreamUrl from "./../../assets/images/sponsors-icons/lifestream.svg";
import dimond from "./../../assets/images/sponsors-icons/dimond.svg";
import soldOutUrl from "./../../assets/images//sponsors-icons/sold_out.svg";
import wifiSponsorUrl from "./../../assets/images/sponsors-icons/wifi-sponsor.svg";

import "./style.scss";
import "swiper/css";
import "swiper/css/pagination";

function DigitalSponsorship({ id }) {
  const { t } = useTranslation();
  const digitalSponsorships = [
    {
      title: "Digital Branding",
      img: digitalBrandingUrl,
      price: "$3 500",
      benefits: "Benefits",
      color: "blue",
      sold: "",
      class: "hidden",
      exclusive: false,
      texts: [
        { one: "Your logo on the website" },
        { two: "Your brand featured on Livestream" },
      ],
    },
    {
      title: "Main LiveStream sponsor",
      img: liveStreamUrl,
      price: "$6 500",
      benefits: "Benefits",
      color: "blue",
      sold: "",
      class: "",
      exclusive: true,
      texts: [
        { one: "Your brand featured on Livestream" },
        { two: "Your 30s promo video played during the Livestream" },
      ],
    },
    {
      title: "WIFI sponsor",
      img: wifiSponsorUrl,
      price: "$5 000",
      benefits: "Benefits",
      color: "blue",
      sold: "sold",
      exclusive: true,
      class: "",
      texts: [
        { one: "Your custom WiFi name" },
        {
          two: (
            <span>
              {t("baltic.honey.redirectionOnTheWeb")} <br /> page of your choice
            </span>
          ),
        },
      ],
    },
  ];

  const digitalSponsorshipsSlides = () =>
    digitalSponsorships.map((sponsorship) => (
      <div
        className={clsx("inner-card", sponsorship?.class, sponsorship?.sold)}
        key={sponsorship.title}
      >
        <div className="sold_out">
          <img src={soldOutUrl} alt="" />
          <h2 className="sold_out__title" title="Sold Out">
            Sold Out
          </h2>
        </div>
        <div className="element">
          <div className={clsx("inner-card__header", sponsorship.color)}>
            <h3 className="card__title">{sponsorship.title}</h3>
            <img src={sponsorship.img} alt="" />
          </div>
          {sponsorship.exclusive && (
            <div className="inner-card__exclusive-ofder">
              <img src={dimond} alt="dimond" />
              Exclusive offer
              <img src={dimond} alt="dimond" />
            </div>
          )}

          <div className="card__body">
            <h3 className="card__money">{sponsorship.price}</h3>
            <p className="card__list-title">{sponsorship.benefits}</p>
            <ul className="card__list">
              {sponsorship.texts?.map((text) => (
                <li key={text.one} className="card__elem">
                  {text?.one || text?.two || text?.three}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    ));

  const screenWidth = window.screen.width;
  let small = screenWidth < 767;

  return (
    <div className="sponsors-block" id={id}>
      <h2 className="sponsors-block__title">
        {t("baltic.honey.digitalSponsorship")}
      </h2>
      <p className="sponsors-block__subtitle">
        {t("baltic.honey.yourBrandFeaturedInBHDigitalEnvironment")}
      </p>

      {small ? (
        <Swiper
          navigation={false}
          pagination={{ clickable: true }}
          modules={[Pagination, A11y]}
          slidesPerView={1}
          spaceBetween={30}
          grabCursor={true}
          className="mySwiper"
        >
          {digitalSponsorships.map((sponsorship) => (
            <SwiperSlide>
              <div
                className={clsx(
                  "inner-card",
                  sponsorship?.class,
                  sponsorship?.sold
                )}
                key={sponsorship.title}
              >
                <div className="sold_out">
                  <img src={soldOutUrl} alt="" />
                  <h2 className="sold_out__title" title="Sold Out">
                    Sold Out
                  </h2>
                </div>
                <div className="element">
                  <div
                    className={clsx("inner-card__header", sponsorship.color)}
                  >
                    <h3 className="card__title">{sponsorship.title}</h3>
                    <img src={sponsorship.img} alt="" />
                  </div>
                  {sponsorship.exclusive && (
                    <div className="inner-card__exclusive-ofder">
                      <img src={dimond} alt="dimond" />
                      Exclusive offer
                      <img src={dimond} alt="dimond" />
                    </div>
                  )}
                  <div className="card__body">
                    <h3 className="card__money">{sponsorship.price}</h3>
                    <p className="card__list-title">{sponsorship.benefits}</p>
                    <ul className="card__list">
                      {sponsorship.texts?.map((text) => (
                        <li key={text.one} className="card__elem">
                          {text?.one || text?.two || text?.three || text?.four}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="inner-cards">{digitalSponsorshipsSlides()}</div>
      )}
    </div>
  );
}

export default DigitalSponsorship;
