import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import "./termsConditions.scss";

function TermsConditions() {
  const { t } = useTranslation();
  const appendix1 = useRef(null);
  const appendix2 = useRef(null);

  const toAppendix1 = () => appendix1.current.scrollIntoView();
  const toAppendix2 = () => appendix2.current.scrollIntoView();

  return (
    <div className="tc-wrapper">
      <div className="tc-wrapper__header">
        <h2 className="tc-wrapper__header__title" title="TERMS & CONDITIONS">
          {t("baltic.honey.termsConditions")}
        </h2>
        <p>{t("baltic.honey.balticHoneybadgerConference2024")}</p>
      </div>
      <div className="tc-wrapper__append">
        <a onClick={toAppendix1} className="tc-wrapper__append__link">
          {t("baltic.honey.appendix1AccessToConference")}
        </a>
        <a onClick={toAppendix2} className="tc-wrapper__append__link">
          {t("baltic.honey.appendix2HouseRules")}
        </a>
        <p className="tc-wrapper__append__text">
          {t("baltic.honey.termsForPurchaseOfConferenceTickets")}
        </p>
      </div>
      <div className="tc-wrapper__container">
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">1. Definitions</p>
          <p className="tc-wrapper__container__text">
            1.1 Agreement: the agreement between the Customer and the Official
            Sales Channel (as applicable) for the purchase of Conference
            tickets. This Agreement consists of these Terms and Conditions, the
            purchase confirmation and other provisions attached to or referred
            to in these Terms and Conditions, and/or the purchase confirmation
            and general terms and conditions of Tickettailor.
            <br />
            1.2 Terms and Conditions: the present general terms and conditions,
            including the appendices.
            <br />
            1.3 Consumer: the natural person who is not acting in the course of
            their commercial, business, craft, or professional activity.
            <br />
            1.4 Customer, you, your: the consumer, legal entity, or de facto
            association that enters into the Agreement for the purchase of
            Conference tickets as provided herein.
            <br />
            1.5 Conference: the 2024 edition of the 'Baltic Honeybadger' Bitcoin
            conference scheduled to take place at Fantadroms, G. Zemgala gatve
            74a, Riga, Latvia, from August 24 to August 25, 2024.
            <br />
            1.6 Conference organizer: Gema Assets SIA, with company number
            52103106731, the organizer and producer of the Conference.
            <br />
            1.7 Conference Products and/or Services: the products and/or
            services related to the Conference.
            <br />
            1.8 Conference Visitor: The natural person who holds a valid
            registered Conference Ticket (as defined in this Agreement) and
            ultimately uses the Conference products and/or services in
            accordance with the Agreement.
            <br />
            1.9 Force Majeure: Any of the following events the occurrence of
            which makes the performance of obligations (excluding payment
            obligations) under the Agreement and/or the Conference impossible,
            impracticable, or unsafe: an act of God (including without
            limitation avalanches), inevitable accident, fire, strike or other
            labor dispute, war or threat of war, an act of a public enemy, an
            act of terrorism or threats thereof, riot or civil commotion,
            national state of emergency, enactment, rule, order or act of any
            government or governmental instrumentality, failure of technical
            facilities, epidemic (including a health epidemic), interruption in,
            or substantial delay of necessary transportation services, or any
            event or condition beyond a party's reasonable control, as
            applicable. Inclement weather shall, in principle, not be deemed
            Force Majeure, provided, however that should such weather conditions
            be severe or threatening enough to render a declaration by the
            applicable public authorities that conditions threaten public
            safety, then such weather condition shall be deemed Force Majeure
            hereunder.
            <br />
            1.10 Official Sales Channel: Conference tickets can only be
            purchased through the Conference Organizer or Ticket Partner.
            Conference tickets offered through the Conference Organizer are
            processed through Tickettailor, the Conference Organizer's service
            provider.
            <br />
            1.11 Tickettailor: Zimma Ltd operates an online platform for ticket
            sales and access management and has been hired by the Conference as
            a supplier to make its platform available to the Conference
            Organizer, who can thus enter into an agreement with the Customer
            for the sale of Conference tickets as mentioned in this Agreement.
            <br />
            1.12 Waiting List: If Conference tickets are sold out, the
            Conference Organizer reserves the right to open a Waiting List. By
            registering on the Waiting List, You authorize the Conference
            Organizer to contact You if tickets are available again.
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">2. Scope of the Terms</p>
          <p className="tc-wrapper__container__subtitle">
            2.1 General Provisions
          </p>
          <p className="tc-wrapper__container__text">
            2.1.1 The attachments are an integral part of these Terms and
            Conditions. <br />
            By purchasing the ticket, you are deemed to have irrevocably agreed
            to these Terms of Service. You can always consult a recent version
            of the Terms and Conditions at www.baltichoneybadger.com; or upon
            request at the Conference customer service.
            <br />
            2.1.2 If you are purchasing Conference tickets not only for yourself
            but also for others, we remind you that it is your sole
            responsibility to inform those people of these Terms and Conditions
            and any other terms and conditions that may apply to your purchase
            and to obtain their Agreement to those terms and conditions prior to
            your purchase.
          </p>
          <p className="tc-wrapper__container__subtitle">2.2 Ticket Partners</p>
          <p className="tc-wrapper__container__text">
            2.2.1 When you purchase Conference tickets through a Ticket Partner,
            the Ticket Partner, as the case may be, is your contracting party.
            You are entering into a direct agreement with them, and you will
            then be subject to their own terms and conditions of sale.
            <br />
            It is the sole responsibility of such Ticket Partner to provide you
            with a copy of the applicable terms and conditions during the
            purchase process and no later than at the conclusion of the
            Agreement with you. <br />
            The Conference Organizer in such a case is exempt from any
            contractual liability to you.
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            3. Conference products and/or services: general provisions
          </p>
          <p className="tc-wrapper__container__subtitle">
            3.1 Official Sales Channels
          </p>
          <p className="tc-wrapper__container__text">
            3.1.1 Conference tickets can only be purchased through the Official
            Sales Channels.
            <br />
            3.1.2 Conference tickets obtained by any other means (through
            another sales channel or by somehow circumventing the procedure
            prescribed at www.baltichoneybadger.com) will not be considered
            valid by the Conference Organizer, and the Conference Organizer
            reserves the right to refuse the Conference ticket holder access to
            the Conference.
          </p>
          <p className="tc-wrapper__container__subtitle">
            3.2 Conference ticket sales
          </p>
          <p className="tc-wrapper__container__text">
            <b>{t("baltic.honey.general")}</b>
            <br />
            3.2.1 The Conference Organizer reserves the right to cancel and
            offer for sale again admission tickets to the Conference that do not
            comply with the provisions of this Article 3. In this case, the
            purchase price for the ticket(s) in question will be refunded to you
            (and only to you) after deduction of the Ticket Refund Fee and
            always in compliance with the cancellation provisions contained in
            this Agreement.
            <br />
            3.2.2 The Conference Organizer reserves the right to cancel your
            purchase for technical reasons (e.g., if existing quotas are
            exceeded).
            <br />
            3.2.3 Each entry ticket for the Conference will be delivered to you
            via an e-ticket
          </p>
          <p className="tc-wrapper__container__text">
            <b>{t("baltic.honey.preSale")}Sale</b> <br />
            3.2.4 The Conference Organizer will make available a pre-sale of
            Conference Tickets. If you subscribe to the conference newsletter
            before the deadline at www.baltichoneybadger.com, you will have the
            opportunity to purchase tickets before the official sale
            announcement.
            <br />
            3.2.5 The Conference Organizer reserves the right to arrange ticket
            sales other than those listed above. If applicable, the special
            conditions for that particular sale will be published on
            www.baltichoneybadger.com so that the buyer can make an informed
            purchase.
          </p>
          <p className="tc-wrapper__container__text">
            <b>{t("baltic.honey.admissionAndIdentityVerification")}</b>
            <br />
            3.2.6 The Conference Organizer reserves the right to check the
            identity of anyone entering the Conference grounds (or to have it
            checked by designated representatives). If one cannot identify
            oneself, the Conference visitor may be refused entry to the
            Conference. A valid identification document can be a valid ID card,
            passport, or driver's license. Copies of identity documents will not
            be accepted.
            <br />
            3.2.7 The Conference Organizer reserves the right to check an
            interoperable vaccination/recovery certificate or proof of a
            negative COVID-19 test result on entry. If it cannot be provided,
            the Conference visitor may be refused entry to the Conference.
            <br />
            3.2.8 The Conference is dedicated to providing a harassment-free
            conference experience for everyone, regardless of gender, gender
            identity, expression, sexual orientation, disability, physical
            appearance, body size, race, or religion. We do not tolerate
            harassment of conference participants in any form. Conference
            participants violating these rules may be sanctioned or expelled
            from the Conference at the conference organizers' discretion.
            <br />
            3.2.9 A denied admission under Article does not entitle anyone to a
            refund.
          </p>
          <p className="tc-wrapper__container__subtitle">
            3.3 Conference Wristband
          </p>
          <p className="tc-wrapper__container__text">
            3.3.1 For each Conference entry ticket purchased, the Conference
            Organizer will provide you with a Conference Wristband, which must
            be worn during the whole event. A Conference Wristband can only
            grant one (1) person access to the Conference.
            <br />
            3.3.2 The Conference Wristband must be picked up at the entrance to
            the Conference and can't be transferred to another person.
          </p>
          <p className="tc-wrapper__container__text">
            <b>{t("baltic.honey.lossOrTheftOfConferenceWristband")}</b>
            <br />
            3.3.3 You are solely responsible for the loss or theft of your
            Conference Wristband upon receipt from the Conference Organizer (in
            accordance with the terms of these Terms and Conditions).
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            4. Cancellations and refunds
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.purchaseAndRefundPolicy")}
            <br />
            If purchases from a Ticket Partner, cancellation and refund policies
            are governed exclusively by the Ticket Partner's terms and
            conditions. The Conference Organiser bears no liability whatsoever.
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">5. Liability</p>
          <p className="tc-wrapper__container__subtitle">
            {" "}
            5.1 Liability of the Customer and Conference Visitor
          </p>
          <p className="tc-wrapper__container__text">
            <b>{t("baltic.honey.refusalOfAccess")}</b>
            <br />
            5.1.1 Anyone who fails to comply with these Terms and Conditions
            will be immediately denied entry or removed from the Conference
            grounds. No refunds will be made unless otherwise agreed here.
            <br />
            5.1.2 The Conference Organizer reserves the right to refuse
            admission to multiple editions of the Conference or other events
            organized by the Conference Organizer and its affiliates, depending
            on the nature of non-compliance with these Terms and Conditions (for
            example, if you sell drugs).
          </p>
          <p className="tc-wrapper__container__text">
            <b>{t("baltic.honey.prosecution")}</b>
            <br />
            The Conference Organizer reserves the right to prosecute any holder
            of counterfeit Conference Tickets and/or Conference Badges.
          </p>
          <p className="tc-wrapper__container__subtitle">
            {" "}
            5.2 Liability of the Conference Organizer
          </p>
          <p className="tc-wrapper__container__text">
            <b>{t("baltic.honey.directDamage")}</b>
            <br />
            5.2.1 The Conference Organizer shall only be liable for damages that
            are the direct and exclusive result of a breach by the Conference
            Organizer. Excluded from compensation are (i) consequential damages
            and (ii) intentional damages caused by deliberate recklessness on
            the part of the party suffering the damage.
            <br />
            5.2.2 The Conference Organizer is not liable for damage, theft, or
            other accidents.
          </p>
          <p className="tc-wrapper__container__text">
            <b>
              {t(
                "baltic.honey.cancellationRelocationOrPostponementOfConference"
              )}
            </b>
            <br />
            5.2.3 The Conference Conference may cancel, relocate or postpone the
            Conference for any reason, including Force Majeure. You have the
            right to compensation for your Ticket value in accordance with the
            applicable law. No further (direct or indirect) damages can be
            claimed.
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            6. Conference tickets purchased from the Conference Organizer
          </p>
          <p className="tc-wrapper__container__subtitle">
            {" "}
            6.1 Platform Tickettailor
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.purchaseOfConferenceTickets")}
          </p>
          <p className="tc-wrapper__container__text">
            6.1.1 The Conference Organizer's Terms and Tickettailor's Terms and
            Privacy Policy are made available in full text and must be accepted
            before a purchase can be made.
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            7. Applicable law and competent court
          </p>
          <p className="tc-wrapper__container__text">
            7.1 These Conditions are governed exclusively by Latvian law, to the
            exclusion of conflict of law rules.
            <br />
            7.2 All disputes shall be settled by the competent court in Riga, to
            the exclusion of all other courts.
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">8. Other provisions</p>
          <p className="tc-wrapper__container__text">
            8.1 If any provision of these Terms and Conditions, or any part
            thereof, is or is declared invalid or unenforceable, the remaining
            provisions of these Terms and Conditions or the remaining part of
            that provision shall remain in full force and effect, and such
            provision shall be automatically replaced (by operation of law) by a
            valid provision as close as possible to the invalid provision.
          </p>
        </div>
      </div>

      <div ref={appendix1} className="tc-wrapper__container__title__append">
        <p className="tc-wrapper__container__title">
          {t("baltic.honey.appendix1AccessToConference")}
        </p>
      </div>
      <div className="tc-wrapper__container">
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            1. Opening hours of the Conference
          </p>
          <p className="tc-wrapper__container__text">
            1.1 The opening hours of the Conference are as follows (subject to
            change):
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.eventDate")} <br />
            10.00 - 18.00
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.eventDate2")} <br />
            10.00 - 18.00
          </p>
          <p className="tc-wrapper__container__text">
            1.2 The lineup of speakers determines the accessibility of the
            number of stages and the opening hours of each stage. Outside of a
            stage's opening hours, the stage area is not accessible to the
            public.
            <br />
            1.3 With a validated Conference Wristband, the Visitor, can check in
            up to one hour before the end of the Conference day; after that
            time, it is no longer possible to enter the Conference grounds.
            <br />
            The Conference Organizer reserves the right to refuse Conference
            visitors who arrive later than one hour before the end of the
            Conference day access to the Conference site, even if the Conference
            visitor is in possession of a valid Conference entry ticket.
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">2. Conference Agenda</p>
          <p className="tc-wrapper__container__text">
            2.1 The Conference Organizer may find itself forced for compelling
            reasons (e.g., weather conditions, security, artist cancellations)
            to change or modify the Conference agenda and to close stages of the
            Conference. In this case, the Conference entry ticket remains valid,
            and no entry tickets will be refunded or exchanged.
          </p>
        </div>
      </div>

      <div ref={appendix2} className="tc-wrapper__container__title__append">
        <p className="tc-wrapper__container__title">
          {t("baltic.honey.appendix2HouseRules")}
        </p>
      </div>
      <p className="tc-wrapper__container__text">
        {t("baltic.honey.houseRules")}
        <br />
        {t("baltic.honey.houseRules2")}
      </p>

      <div className="tc-wrapper__container">
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.conferenceTicketPolicy")}
          </p>
          <p className="tc-wrapper__container__text">
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.ticketRefundability")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.lossOrTheftPolicy")}{" "}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.identityVerification")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.resalePolicy")}{" "}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.lineupChangesPolicy")}{" "}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.ticketForgeryPolicy")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.accessRestrictions")}
            </span>
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.accessControlTitle")}
          </p>
          <p className="tc-wrapper__container__text">
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.accessControlDescription")}{" "}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.entryDenialPolicy")}{" "}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.alcoholOrDrugs")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.drugPossessionOrDealing")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.pastDenialOfEntry")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.disruptiveBehavior")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.endangeringLives")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.prohibitedItemsPossession")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.refuse_search_security_personnel")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.refuse_comply_terms_conditions")}
            </span>
          </p>
        </div>

        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.security_control")}
          </p>
          <p className="tc-wrapper__container__text">
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.acceptance_conference_grounds")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.searched_by_security_personnel")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.security_personnel_rights")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.compliance_conference_premises")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.conference_organizer_rights")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.pause_stop_conference")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.prevent_visitors_leaving_conference")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.ask_visitors_leave_conference")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.deny_visitors_access_conference")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.conference_visitors_not_allowed")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.enter_areas_not_grant_access")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.climb_on_conference_structures")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.block_entrances_exits")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.disguise_unrecognizable")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.sell_without_permission")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.throw_or_shoot_objects")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.smoke_in_prohibited_places")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.urinate_in_public")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.destroy_decorations")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.steal_decorations")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.make_fire")}
            </span>
            <span className="tc-wrapper__container__text__dash">
              {t("baltic.honey.wear_prohibited_texts_symbols")}
            </span>
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.prohibited_items")}
          </p>
          <p className="tc-wrapper__container__subtitle">
            {t("baltic.honey.prohibited_items_list")}
          </p>
          <p className="tc-wrapper__container__text">
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.drugs_and_illegal_substances")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.other_objects_security_risk")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.projectiles_or_explosives")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.flammable_products")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.pyrotechnic_objects")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.other_weapons_sharp_objects")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.disc_prohibited_banners_objects")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.animals_except_guide_dogs")}
            </span>
            <span className="tc-wrapper__container__text__list">
              {t("baltic.honey.drones")}
            </span>
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.conference_grounds_damage_materials")}
          </p>
          <p className="tc-wrapper__container__text">
            'Fantadroms', the location of the Conference, is an event venue, and
            all visitors are requested to do their part to keep the surroundings
            as clean as possible. On the Conference site, it is clearly
            indicated where you can leave your waste.
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.conference_organizer_effort_respect")}
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.merchandise")}
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.conference_official_merchandise")}
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.photographs_video_recordings")}
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.photo_video_recordings_notice")}
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.camera_surveillance_legislation")}
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.camera_usage_restriction")}{" "}
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.promotion")}
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.flyers_posters_prohibited")}
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.safety_around_stage_loss_damage")}
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.artists_organizers_not_responsible")}
          </p>
        </div>
        <div className="tc-wrapper__container__block">
          <p className="tc-wrapper__container__title">
            {t("baltic.honey.overview")}
          </p>
          <p className="tc-wrapper__container__text">
            {t("baltic.honey.cancel_or_postpone_conference")}
            <br />
            {t("baltic.honey.cancel_or_postpone_conference2")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;
