import React from "react";
import { useTranslation } from "react-i18next";
import bitcoinWorldConference from "./bitcoin-world-conference.svg";
import bitcoinConferenceEurope from "./bitcoin-conference-europe.svg";
import bitcoinEvent2023 from "./bitcoin-event-2023.svg";
import joinhb from "../../assets/images/honeybadger_lightning.webp";
import nostrLogo from "./nostr-logo.svg";
import ButtonContained from "../ButtonContained/ButtonContained";
import "./style.scss";

function JoinCommunity() {
  const { t } = useTranslation();

  return (
    <div className="joinCommunity" id="eventbannerjs">
      <h2 className="joinCommunity__title" title="Join our community">
        {t('Join our community')}
      </h2>
      <div className="joinCommunity__buttons">
        <ButtonContained>
          <a
            href="https://twitter.com/hodlhodl"
            target="_blank"
            className="joinCommunity__buttons__button__link"
            rel="noreferrer"
          >
            <img
              alt="Best Bitcoin Conference in Europe"
              src={bitcoinConferenceEurope}
            />
            {t('Follow us')}
          </a>
        </ButtonContained>
        <ButtonContained>
          <a
            href="http://primal.net/p/npub1yul83qxn35u607er3m7039t6rddj06qezfagfqlfw4qk5z5slrfqu8ncdu"
            target="_blank"
            className="joinCommunity__buttons__button__link"
            rel="noreferrer"
          >
            <img alt="Nostr logo" src={nostrLogo} />
            {t('Follow us')}
          </a>
        </ButtonContained>
        <ButtonContained>
          <a
            href="https://t.me/baltichoneybadger"
            target="_blank"
            className="joinCommunity__buttons__button__link"
            rel="noreferrer"
          >
            <img
              alt="Best Bitcoin conference in the world"
              src={bitcoinWorldConference}
            />
            {t('Latest news')}
          </a>
        </ButtonContained>
        <ButtonContained>
          <a
            href="https://www.youtube.com/c/HodlHodl"
            target="_blank"
            className="joinCommunity__buttons__button__link"
            rel="noreferrer"
          >
            <img alt="Bitcoin events in 2023" src={bitcoinEvent2023} />
            {t('Youtube')}
          </a>
        </ButtonContained>
      </div>
      <img
        alt="Bitcoin Conference 2023 Dates"
        src={joinhb}
        className="joinCommunity__img"
      />
    </div>
  );
}

export default JoinCommunity;
