import {cnblock} from "../../utils/cn";
import ButtonContained from "../../components/ButtonContained/ButtonContained";
import yotubeIcon from "./youtube-icon.svg";
import React from "react";

export function ConferenceTicketsBanner({title, text, url, urlText}) {
    const cn = cnblock('ctbanner')

    return (
        <div className="ctbanner-container">
            <section className={cn()}>
                <span className={cn('title')} title={title}>
                  {title}
                </span>
                <p className={cn('text')}>
                    {text}
                </p>
                <div className={cn('button')}>
                    <ButtonContained>
                        <a
                            href={url}
                            className={cn('link')}
                            title={urlText}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={yotubeIcon} alt="Youtube"/>
                            {urlText}
                        </a>
                    </ButtonContained>
                </div>
            </section>
        </div>
    )
}
