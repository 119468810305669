import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y } from "swiper";

import exclusiveUrl from "./../../assets/images/sponsors-icons/exclusive.svg";
import soldOutUrl from "./../../assets/images//sponsors-icons/sold_out.svg";
import { useIsMobile } from "../../hooks/useMediaQuery";

import "./style.scss";
import "swiper/css";
import "swiper/css/pagination";

function MainBlock({ id }) {
  const { t } = useTranslation();
  let small = useIsMobile();

  return (
    <div className="sponsors-block" id={id}>
      <h2 className="sponsors-block__title">
        {t("baltic.honey.mainSponsors")}
      </h2>
      <p className="sponsors-block__subtitle main-block">
        {t("baltic.honey.mainSponsorsExposure")}
        <wbr />
        {t("baltic.honey.mainSponsorsExposuresecond")}
      </p>
      {small ? (
        <div className="sponsor-swiper">
          <Swiper
            navigation={false}
            pagination={{ clickable: true }}
            modules={[Pagination, A11y]}
            slidesPerView={1}
            spaceBetween={30}
            grabCursor={true}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="card">
                <div className="sold_out">
                  <img src={soldOutUrl} alt="" />
                </div>
                <div className="element">
                  <div className="card__header blue">
                    <h3 className="card__title">{t("baltic.honey.shark")}</h3>
                  </div>
                  <div className="card__body">
                    <h3 className="card__money">$8 500</h3>
                    <p className="card__description">
                      3 BH passes{" "}
                      <span className="card__descriptionAddition">
                        {t("baltic.honey.sharkAccessToClosedEvents")}
                      </span>
                    </p>
                    <p className="card__list-title">
                      {t("baltic.honey.brandingIncluded")}
                    </p>
                    <ul className="card__list">
                      <li className="card__elem">
                        {t("baltic.honey.cypherpunkStage")}
                      </li>
                      <li className="card__elem">{t("baltic.honey.lobby")}</li>
                      <li className="card__elem">
                        {t("baltic.honey.merchandise")}
                      </li>
                      <li className="card__elem">
                        {t("baltic.honey.liveStream")}
                      </li>
                      <li className="card__elem">
                        {t("baltic.honey.website")}
                      </li>
                    </ul>
                    <div className="card__info">
                      <span className="card__span">
                        {t("baltic.honey.boothSpace")}
                      </span>
                      <span className="card__span">
                        {t("baltic.honey.newsletter")}
                      </span>
                      <span className="card__span">
                        {t("baltic.honey.noBagDrop")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="sold_out">
                  <img src={soldOutUrl} alt="" />
                </div>
                <div className="element">
                  <div className="card__header red">
                    <h3 className="card__title">{t("baltic.honey.whale")}</h3>
                  </div>
                  <div className="card__body">
                    <h3 className="card__money">$11 500</h3>
                    <p className="card__description">
                      5 BH passes{" "}
                      <span className="card__descriptionAddition">
                        {t("baltic.honey.sharkAccessToClosedEvents")}
                      </span>
                    </p>
                    <p className="card__list-title">
                      {t("baltic.honey.brandingIncluded")}
                    </p>
                    <ul className="card__list">
                      <li className="card__elem">
                        {t("baltic.honey.mainStage")}
                      </li>
                      <li className="card__elem">{t("baltic.honey.lobby")}</li>
                      <li className="card__elem">
                        {t("baltic.honey.merchandise")}
                      </li>
                      <li className="card__elem">
                        {t("baltic.honey.liveStream")}
                      </li>
                      <li className="card__elem">
                        {t("baltic.honey.website")}
                      </li>
                    </ul>
                    <div className="card__info">
                      <span className="card__span">
                        {t("baltic.honey.boothSpace")}
                      </span>
                      <span className="card__span">
                        {t("baltic.honey.newsletter")}
                      </span>
                      <span className="card__span bag">
                        {t("baltic.honey.bagDrop")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="sold_out">
                  <img src={soldOutUrl} alt="" />
                </div>
                <div className="element">
                  <div className="card__header black exclusive">
                    <h3 className="card__title">
                      {t("baltic.honey.humpback")}
                    </h3>
                    <img src={exclusiveUrl} alt="exclusiveUrl" />
                  </div>
                  <div className="card__body">
                    <h3 className="card__money">$15 000</h3>
                    <p className="card__description">
                      10 BH passes{" "}
                      <span className="card__descriptionAddition">
                        {t("baltic.honey.sharkAccessToClosedEvents")}
                      </span>
                    </p>
                    <p className="card__list-title">
                      {t("baltic.honey.brandingIncluded")}
                    </p>
                    <ul className="card__list">
                      <li className="card__elem">
                        {t("baltic.honey.foodCourt")}
                      </li>
                      <li className="card__elem">
                        {t("baltic.honey.mainStage")}
                      </li>
                      <li className="card__elem">{t("baltic.honey.lobby")}</li>
                      <li className="card__elem">
                        {t("baltic.honey.merchandise")}
                      </li>
                      <li className="card__elem">
                        {t("baltic.honey.liveStream")}
                      </li>
                      <li className="card__elem">
                        {t("baltic.honey.website")}
                      </li>
                    </ul>
                    <div className="card__info">
                      <span className="card__span">
                        {t("baltic.honey.honeybadgerBooth")}
                      </span>
                      <span className="card__span">
                        {t("baltic.honey.newsletter")}
                      </span>
                      <span className="card__span bag">
                        {t("baltic.honey.bagDrop")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      ) : (
        <div className="main-cards">
          <div className="card">
            <div className="sold_out">
              <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
              <div className="card__header blue">
                <h3 className="card__title">{t("baltic.honey.shark")}</h3>
              </div>
              <div className="card__body">
                <h3 className="card__money">$8 500</h3>
                <p className="card__description">
                  3 BH passes{" "}
                  <span className="card__descriptionAddition">
                    {t("baltic.honey.sharkAccessToClosedEvents")}
                  </span>
                </p>
                <p className="card__list-title">
                  {t("baltic.honey.brandingIncluded")}{" "}
                </p>
                <ul className="card__list">
                  <li className="card__elem">
                    {t("baltic.honey.cypherpunkStage")}
                  </li>
                  <li className="card__elem">{t("baltic.honey.lobby")}</li>
                  <li className="card__elem">
                    {t("baltic.honey.merchandise")}
                  </li>
                  <li className="card__elem">{t("baltic.honey.liveStream")}</li>
                  <li className="card__elem">{t("baltic.honey.website")}</li>
                </ul>
                <div className="card__info">
                  <span className="card__span">
                    {t("baltic.honey.boothSpace")}
                  </span>
                  <span className="card__span">
                    {t("baltic.honey.newsletter")}
                  </span>
                  <span className="card__span">
                    {t("baltic.honey.noBagDrop")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="sold_out">
              <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
              <div className="card__header red">
                <h3 className="card__title">{t("baltic.honey.whale")}</h3>
              </div>
              <div className="card__body">
                <h3 className="card__money">$11 500</h3>
                <p className="card__description">
                  5 BH passes{" "}
                  <span className="card__descriptionAddition">
                    {t("baltic.honey.sharkAccessToClosedEvents")}
                  </span>
                </p>
                <p className="card__list-title">
                  {t("baltic.honey.brandingIncluded")}
                </p>
                <ul className="card__list">
                  <li className="card__elem">{t("baltic.honey.mainStage")}</li>
                  <li className="card__elem">{t("baltic.honey.lobby")}</li>
                  <li className="card__elem">
                    {t("baltic.honey.merchandise")}
                  </li>
                  <li className="card__elem">{t("baltic.honey.liveStream")}</li>
                  <li className="card__elem">{t("baltic.honey.website")}</li>
                </ul>
                <div className="card__info">
                  <span className="card__span">
                    {t("baltic.honey.boothSpace")}
                  </span>
                  <span className="card__span">
                    {t("baltic.honey.newsletter")}
                  </span>
                  <span className="card__span bag">
                    {t("baltic.honey.bagDrop")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="sold_out">
              <img src={soldOutUrl} alt="" />
            </div>
            <div className="element">
              <div className="card__header black exclusive">
                <h3 className="card__title">{t("baltic.honey.humpback")}</h3>
                <img src={exclusiveUrl} alt="exclusiveUrl" />
              </div>
              <div className="card__body">
                <h3 className="card__money">$15 000</h3>
                <p className="card__description">
                  10 BH passes{" "}
                  <span className="card__descriptionAddition">
                    {t("baltic.honey.sharkAccessToClosedEvents")}
                  </span>
                </p>
                <p className="card__list-title">
                  {t("baltic.honey.brandingIncluded")}
                </p>
                <ul className="card__list">
                  <li className="card__elem">{t("baltic.honey.foodCourt")}</li>
                  <li className="card__elem">{t("baltic.honey.mainStage")}</li>
                  <li className="card__elem">{t("baltic.honey.lobby")}</li>
                  <li className="card__elem">
                    {t("baltic.honey.merchandise")}
                  </li>
                  <li className="card__elem">{t("baltic.honey.liveStream")}</li>
                  <li className="card__elem">{t("baltic.honey.website")}</li>
                </ul>
                <div className="card__info">
                  <span className="card__span">
                    {t("baltic.honey.honeybadgerBooth")}
                  </span>
                  <span className="card__span">
                    {t("baltic.honey.newsletter")}
                  </span>
                  <span className="card__span bag">
                    {t("baltic.honey.bagDrop")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainBlock;
