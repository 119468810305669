import React, { useEffect } from "react";
import gsap from "gsap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import speakers from "../../speakers.json";
import arrowUpUrl from "./../../assets/images/arrow-up.svg";
import twitterIcon from "./../../assets/images/twitter.svg";
import nostrIcon from "./../../assets/images/nostr.svg";

import "./style.scss";

const IMAGE_URI = "/images/speakers/";

const SpeakersList = React.memo(() => {
  const { t } = useTranslation();
  let path = window.location.pathname;
  let content = null;
  const isIndexPage = path === "/";

  const screenWidth = window.screen.width;
  let tablet = screenWidth <= 1200;
  let mobile = screenWidth < 768;

  const mapSpeaker = (speaker) => {
    const url = IMAGE_URI + speaker.image;
    return (
      <figure key={speaker.id} id={speaker.id} className="person">
        <img src={url} alt={speaker.image} className="person__image" />
        {speaker.remotely && (
          <div className="person__remotely">{t("baltic.honey.remotely")}</div>
        )}
        <figcaption>
          <h2 className="block__title">{speaker.name}</h2>
          <p className="block__text">{speaker.title}</p>
          <p className="block__icons">
            {speaker.twitUrl && (
              <a
                href={speaker.twitUrl}
                className="person__link"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitterIcon} alt="Twitter" />
              </a>
            )}
            {speaker.nostrUrl && (
              <a
                href={speaker.nostrUrl}
                className="person__link"
                target="_blank"
                rel="noreferrer"
              >
                <img src={nostrIcon} alt="Nostr" />
              </a>
            )}
          </p>
        </figcaption>
      </figure>
    );
  };

  if (isIndexPage) {
    if (tablet && !mobile) {
      content = speakers
        .filter((item) => item.main === true)
        .filter((item) => item.id <= 6)
        .map((speaker) => mapSpeaker(speaker));
    } else if (mobile) {
      content = speakers
        .filter((item) => item.main === true)
        .filter((item) => item.id <= 4)
        .map((speaker) => mapSpeaker(speaker));
    } else {
      content = speakers
        .filter((item) => item.main === true)
        .map((speaker) => mapSpeaker(speaker));
    }
  } else {
    content = speakers.map((speaker) => mapSpeaker(speaker));
  }

  return (
    <section className="persons" id="persons">
      {isIndexPage && (
        <h2 className="persons__index__title">
          <span className="persons__index__title__yellow">
            {t("baltic.honey.highprofilespeakers")}
          </span>{" "}
          from all around the world
        </h2>
      )}
      <div
        className={`${
          isIndexPage ? "persons__wrap persons__wrap__four " : "persons__wrap"
        }`}
      >
        {content}
      </div>
      {isIndexPage && (
        <Link to="/speakers" className="persons__wrapper">
          <p className="persons__link">
            {t("baltic.honey.allconferencespeakers")}
          </p>
          <img src={arrowUpUrl} alt="Speakers" className="persons__image" />
        </Link>
      )}
    </section>
  );
});

export default SpeakersList;
