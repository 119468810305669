import {useCallback, useRef} from "react";
import {useWindowEvent} from "./useWindowEvent";

export function useScroll(cb) {
    const cbRef = useRef(cb);
    const prevScrollYRef = useRef(window.scrollY);

    const onScroll = useCallback(() => {
        cbRef.current(window.scrollY, prevScrollYRef.current);

        prevScrollYRef.current = window.scrollY;
    }, []);

    useWindowEvent("scroll", onScroll);
}
