import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { useIsMobile } from "../../hooks/useMediaQuery";
import SpeakersList from "./../../components/SpeakersList/SpeakersList";

import "./speakers.scss";

function Speakers() {
  const { t } = useTranslation();
  let small = useIsMobile();
  return (
    <div className="page speakers">
      <Helmet>
        <title>{t("baltic.honey.baltic_speakers")}</title>
        <meta
          name="description"
          content="List of Speakers for the Baltic Honeybadger Conference."
        />
      </Helmet>
      {small ? (
        <h2 className="speakers__title" title="The loudest voices in Bitcoin">
          {t("baltic.honey.loudestVoicesInBitcoin")}
        </h2>
      ) : (
        <h2 className="speakers__title" title="The loudest voices in Bitcoin">
          {t("baltic.honey.loudestVoicesInBitcoin")}
        </h2>
      )}
      <h1 className="speakers__subtitle">
        {t("baltic.honey.balticHoneybadger2024Speakers")}
      </h1>
      <SpeakersList />

      {/* <AnnouncedSoon
        lastYearLink="https://2023.baltichoneybadger.com/speakers"
        lastYearTitle="baltic honeybadger 2023 speakers"
        subtitle="Have you seen the presentations from the previous year?"
        title="Speakers will be announced soon!"
      />
      <VideoSlider /> */}
    </div>
  );
}

export default Speakers;
