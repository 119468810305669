/* eslint-disable react/style-prop-object */
import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
// import agendafirst from "../../agendafirst.json";
// import agendasecond from "../../agendasecond.json";

import {useIsMobile} from "../../hooks/useMediaQuery";
// import VideoSlider from "../../components/VideoSlider/VideoSlider";
// import AnnouncedSoon from "../../components/AnnouncedSoon/AnnouncedSoon";

import "./style.scss";

// const agendaItem = (items, type, isMobile) => {
//   return type === "all"
//     ? items.map((item) => {
//         return item.type === "lunch" ? (
//           <div className={`agenda__content__item ${item.type}`}>
//             <h2
//               className={`agenda__content__item__lunch__time`}
//               title={item.title}
//             >
//               {item.time}
//             </h2>
//             <h2
//               className={`agenda__content__item__lunch__title`}
//               title={item.title}
//             >
//               {item.title}
//             </h2>
//           </div>
//         ) : (
//           <div className="agenda__content__item">
//             <div className={`agenda__content__item__title ${item.type}`}>
//               <p className="agenda__content__item__title__time">{item.time}</p>
//               <p className="agenda__content__item__title__subtitle">
//                 {item.subtitle}
//               </p>
//               {isMobile && (
//                 <h2
//                   className={`agenda__content__item__title__type ${item.type}`}
//                   title={item.stage}
//                 >
//                   {item.stage}
//                 </h2>
//               )}
//             </div>
//             <div className="agenda__content__item__text">
//               <p className="agenda__content__item__text__title">{item.title}</p>
//               <p className="agenda__content__item__text__author">
//                 {item.author}
//               </p>
//             </div>
//             {!isMobile && (
//               <div className="agenda__content__item__stage">
//                 <h2
//                   className={`agenda__content__item__stage__title ${item.type}`}
//                   title={item.stage}
//                 >
//                   {item.stage}
//                 </h2>
//               </div>
//             )}
//           </div>
//         );
//       })
//     : items
//         .filter((item) => item.type === type || item.type === "lunch")
//         .map((item) => {
//           return item.type === "lunch" ? (
//             <div className={`agenda__content__item ${item.type}`}>
//               <h2
//                 className={`agenda__content__item__lunch__time`}
//                 title={item.title}
//               >
//                 {item.time}
//               </h2>
//               <h2
//                 className={`agenda__content__item__lunch__title`}
//                 title={item.title}
//               >
//                 {item.title}
//               </h2>
//             </div>
//           ) : (
//             <div className="agenda__content__item">
//               <div className={`agenda__content__item__title ${item.type}`}>
//                 <p className="agenda__content__item__title__time">
//                   {item.time}
//                 </p>
//                 <p className="agenda__content__item__title__subtitle">
//                   {item.subtitle}
//                 </p>
//                 {isMobile && (
//                   <h2
//                     className={`agenda__content__item__title__type ${item.type}`}
//                     title={item.stage}
//                   >
//                     {item.stage}
//                   </h2>
//                 )}
//               </div>
//               <div className="agenda__content__item__text">
//                 <p className="agenda__content__item__text__title">
//                   {item.title}
//                 </p>
//                 <p className="agenda__content__item__text__author">
//                   {item.author}
//                 </p>
//               </div>
//               {!isMobile && (
//                 <div className="agenda__content__item__stage">
//                   <h2
//                     className={`agenda__content__item__stage__title ${item.type}`}
//                     title={item.stage}
//                   >
//                     {item.stage}
//                   </h2>
//                 </div>
//               )}
//             </div>
//           );
//         });
// };

const link = 'https://pretalx.com/baltic-honeybadger-2024/'
// const widgetLink = 'https://pretalx.com/baltic-honeybadger-2024/widgets/schedule.js'
const widgetLink = '/a/pretalx-schedule.min.js'
const style = {
  '--pretalx-clr-primary': '#ffb52e',
  '--track-color': '#ffb52e',
}

const Agenda = React.memo(function Agenda() {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  //   const [day, setDay] = useState("first");
  //   const [type, setType] = useState("all");
  //   const isMobile = useIsMobile();

  //   const agendaItems =
  //     day === "first"
  //       ? agendaItem(agendafirst, type, isMobile)
  //       : agendaItem(agendasecond, type, isMobile);


  return (
      <>
          <div>
              <section className="agenda">
                  <Helmet>
                      <title>{t("baltic.honey.bhb2024agenda")}</title>
                      <meta
                          name="description"
                          content="Check out the agenda for Baltic Honeybadger 2024 conference."
                      />
                      <script type="text/javascript" src={widgetLink}></script>
                  </Helmet>
                  <h1 className="agenda__title" title="Baltic Honeybadger agenda 2024">
                      {t("baltic.honey.conferenceagenda2024")}
                  </h1>
                  <p className="agenda__subtitle">
                      {t("baltic.honey.bhbbitcoinconferenceschedule")}
                  </p>

                  {/* <div className='agenda__types'>
                <div className='agenda__types__day'>
                    <span className={`agenda__types__day__item ${day === 'first' && 'active'}`} onClick={() => setDay('first')}>Day 1</span>
                    <span className={`agenda__types__day__item ${day === 'second' && 'active'}`} onClick={() => setDay('second')}>Day 2</span>
                </div>
                <div className='agenda__types__type'>
                    <span className={`agenda__types__type__item ${type === 'all' && 'active'}`} onClick={() => setType('all')}>All stages</span>
                    <span className={`agenda__types__type__item ${type === 'main' && 'active'}`} onClick={() => setType('main')}>Main stage</span>
                    <span className={`agenda__types__type__item ${type === 'cypherpunk' && 'active'}`} onClick={() => setType('cypherpunk')}>Cypherpunk stage</span>
                    <span className={`agenda__types__type__item ${type === 'pow' && 'active'}`} onClick={() => setType('pow')}>PoW stage</span>
                </div>
            </div>

            <div className='agenda__content'>
                {agendaItems}
            </div> */}

                  {/*<AnnouncedSoon*/}
                  {/*    lastYearLink="https://2023.baltichoneybadger.com/agenda"*/}
                  {/*    lastYearTitle="baltic honeybadger 2023 agenda"*/}
                  {/*    subtitle="Have you seen the presentations from the previous year?"*/}
                  {/*    title="Agenda will be announced soon!"*/}
                  {/*/>*/}
              </section>
          </div>
          <div className="agenda-schedule">
              <pretalx-schedule
                  event-url={link}
                  locale="en"
                  format={isMobile ? "list" : "grid"}
                  style={style}
              ></pretalx-schedule>
          </div>
          {/*<VideoSlider/>*/}
      </>
  );
})

export default Agenda;
