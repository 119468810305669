import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import email from "./email.svg";
import facebook from "./facebook.svg";
import { useIsMobile } from "../../hooks/useMediaQuery";

import "./style.scss";

function Footer() {
  const { t } = useTranslation();
  let small = useIsMobile();

  return (
    <footer className="footer">
      <div className="footer__block">
        <p className="footer__block__item__link">
          <a href="https://fb.me/e/1QvyBxx6W" target="_blank" rel="noreferrer">
            <span className="footer__block__item__link__icon">
              <img src={facebook} alt="facebook" />
            </span>
            {t('FACEBOOK')}
          </a>
        </p>
        <p className="footer__block__item__link">
          <a href="mailto:info@baltichoneybadger.com">
            <span className="footer__block__item__link__icon">
              <img src={email} alt="email" />
            </span>
            info@baltichoneybadger.com
          </a>
        </p>
        <p className="footer__block__item">
          © {t("baltic.honey.balticHoneybadgerConference2024")}
        </p>
      </div>
      <div className="footer__block">
        <h2 className="footer__block__title" title="Baltic HoneyBadger 2024">
          {t("baltic.footer.title")}
        </h2>
      </div>
      <div className="footer__block footer__block__right">
        {small ? (
          <>
            <div className="footer__block__right__mobile">
              <p className="footer__block__item__link">
                <a
                  href="https://tally.so/r/wkABad"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('FOSS tickets')}
                </a>
              </p>
              <p className="footer__block__item__link">
                <a
                  href="https://goo.gl/maps/5P3o9oKUftMqwi2e6"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("baltic.footer.location")}
                </a>
              </p>
            </div>
            <div className="footer__block__right__mobile">
              <p className="footer__block__item__link">
                <Link to="/agenda" alt="Agenda">
                  {t("baltic.footer.agenda")}
                </Link>
              </p>
              <p className="footer__block__item__link">
                <Link to="/terms-conditions" alt="Terms">
                  {t("baltic.footer.terms")}
                </Link>
              </p>
            </div>
          </>
        ) : (
          <>
            {/*<p className='footer__block__item__link'><Link to="/register"  alt="Register">Register</Link></p>*/}
            <p className="footer__block__item__link">
              <a
                href="https://tally.so/r/wkABad"
                target="_blank"
                rel="noreferrer"
              >
                {t('FOSS tickets')}
              </a>
            </p>
            <p className="footer__block__item__link">
              <Link to="/agenda" alt="Agenda">
                {t("baltic.footer.agenda")}
              </Link>
            </p>
            <p className="footer__block__item__link">
              <a
                href="https://goo.gl/maps/5P3o9oKUftMqwi2e6"
                target="_blank"
                rel="noreferrer"
              >
                {t("baltic.footer.location")}
              </a>
            </p>
            <p className="footer__block__item__link">
              <Link to="/terms-conditions" alt="Terms">
                {t("baltic.footer.terms")}
              </Link>
            </p>
          </>
        )}
      </div>
    </footer>
  );
}

export default Footer;
